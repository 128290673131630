import React from 'react';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { color, font, sizing } from '../../utils/style';

const SlideWrap = styled('div')`
  & .text {
      width: 100%;
      margin-top: 8px;
      
      font-family: ${font.default};
      font-style: normal;
      font-weight: normal;
      font-size: ${sizing.text};
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;
      color:${color.white};
    }
    
`;

const ImgWrapper = styled('div')`
  border-radius: 8px;
  overflow: hidden;
`;

const SlickSlideMenuContentMobile = ({ img, altText, title, slideUrl }) => (
  <SlideWrap>
    <Link to={slideUrl} className="game">
      <ImgWrapper>
        <Img fluid={img} alt={altText || 'slide'} />
      </ImgWrapper>
      <div className="text">
        <span className="title">{title}</span>
      </div>
    </Link>
  </SlideWrap>
);

export default SlickSlideMenuContentMobile;
