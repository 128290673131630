import React, { useCallback, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import Button from './Button';
import { color, border, area, breakpoints } from '../../utils/style';
import coolHand from '../../img/cool-hand.svg';
import lnwasia from '../../img/Lnvasia.svg';

const Bg = styled('div')`
  position: relative;
  padding: 0 0 30px 0;
  margin-top: 60px;
  height: auto;
  min-height: 350px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.bunting {
    background-color: ${color.bunting};
    border-radius: 8px;
    margin-top: 30px;
    padding: 30px 24px;

    & > img {
      left: 20px !important;
    }  
  }
  &.gray {
    background-color: ${color.blocklightPurple};
    border-radius: 8px;
    margin-top: 30px;
    padding: 30px 24px;

    & > img {
      left: 20px !important;
    }
  }

  .image {
    width: max-content;
    min-width: 165px;

    .iconImage {
      width: 165px;
      height: 199px;
    }

    @media (max-width: ${breakpoints.md}) {
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      padding: 20px 0 25px 0;
    }
  }

  & > img {
    position: absolute;
    top: 0;
    right: 0;

    :nth-of-type(1) {
      left: 0;
      top: 0;

      @media (max-width: ${breakpoints.tl}) {
        width: 50px;
      }
    }
  }

  & h2 {
    margin-left: 60px;
    margin-top: 10px;
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 26px;
    font-weight: bold;

    @media (max-width: ${breakpoints.tl}) and (min-width: ${breakpoints.tl}) and (orientation: landscape) {
      margin-left: 5%;
    }
    @media (max-width: ${breakpoints.sm}) and (min-width: ${breakpoints.sm}) and (orientation: portrait) {
      margin-left: 2%;
    }
    @media (max-width: ${breakpoints.md}) {
      margin: 10px 0 23px 60px;
      font-size: 22px;
      font-weight: bold;
      color: ${color.lightningYellow};
      padding-left: 0;
    }
  }

  & > div {
    width: 80%;
    padding-bottom: 30px;

    @media (max-width: ${breakpoints.md}) {
      width: 100%;
    }
  }

  .compress {
    & .min {
      display: block;
    }
    & .max {
      display: none;
    }
  }

  .rotate {
    & .min {
      display: none;
    }

    & .max {
      display: block;
    }

    svg {
      transform: rotate(180deg);
    }
  }

  .button {
    position: relative;
    width: 133px;
    height: 42px;
    border-radius: ${border.borderRadius14};
    border: solid 1px ${color.lightGrey};
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 16px;
    color: ${color.lightGrey};
    line-height: 1.2rem;
    margin-top: 20px;
    transition: unset;
    z-index: 4;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    outline: none;

    & * {
      outline: none;
    }

    a {
      padding: 10px 20px;
    }
    span {
      font-size: 16px;
      color: ${color.lightGrey};
    }
    svg {
      //position: absolute;
      //margin-top: 0;
      //right: 5px;
    }
  }

  & .content {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-bottom: 0;

    @media (max-width: ${breakpoints.md}) {
      flex-direction: column;
    }

    .wp-content {
      padding-right: 40px;

      @media (max-width: ${breakpoints.md}) {
        padding-right: 0;
      }
    }
  }

  @media (max-width: ${breakpoints.xs}) {    
    margin-top: 30px;
    padding: 0 0 30px 0;

    .button {
      border-radius: ${border.borderRadius8};
    }
  }
`;

const WhySbobet = ({ mode = '' }) => {
  const [isCompress, setIsCompress] = useState('compress');
  const readMore = useCallback(() => {
    setIsCompress(!isCompress);
  }, [isCompress]);

  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "why-sbobet" } }) {
        edges {
          node {
            title
            content
          }
        }
      }
    }
  `);
  const page = data?.allWordpressPage?.edges.length > 0 ? data?.allWordpressPage?.edges[0].node : {};

  return (
    <Bg id="why-sbobet" className={mode}>
      <img className="iconImage" src={coolHand} alt="coolhand" />
      <h2>{page.title || ''}</h2>
      <div className={`${isCompress ? 'compress' : 'rotate'} content`}>
        <div dangerouslySetInnerHTML={{ __html: page.content ?? null }} />
        <div className="image">
          <img className="iconImage" src={lnwasia} alt="lnwasia" />
        </div>
      </div>
      <Button
        text={isCompress ? 'อ่านเพิ่มเติม' : 'อ่านน้อยลง'}
        ariaText="อ่านเพิ่มเติม"
        className={isCompress ? 'button withBorder' : 'button withBorder rotate'}
        iconName="arrow-down-simple"
        iconColor={color.lightGrey}
        iconSize="15"
        customHandler={readMore}
      />
    </Bg>
  );
};

export default WhySbobet;
